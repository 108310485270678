$(document).on("ready turbolinks:load", function() {
  $(".calendar")
    .fullCalendar("destroy")
    .empty();
  setTimeout(function() {
    $(".calendar").fullCalendar({
      header: {
        left: "prev,next",
        center: "title",
        right: "today"
      },
      height: "parent",
      eventColor: "#094c07",
      allDayDefault: true,
      startParam: "date_start",
      endParam: "date_end",
      events: {
        url: "/calendar/json/" + location.search,
        success: function(response) {
          return response.events;
        }
      },
      eventDataTransform: function(event) {
        return {
          title: event.title,
          start: event.date,
          url: event.url,
          color: event.color
        };
      },
      eventRender: function(event, element) {
        $(element).tooltip({
          title: event.title
        });
      },
      eventClick: function(calEvent) {
        Turbolinks.visit(calEvent.url);
      }
    });
  }, 1000);
});
