

$(document).on('ready turbolinks:load', function() {
  let timer = null;

  function searchLive(query) {
    timer = null;
    $.get(
      '/search/live/?query=' + query,
      function(data) {
        $('#search-live-results').html(data);
      }
    );
  }

  $('#search-query').on('input', function() {
    const query = $(this).val();
    if (query.length > 0) {
      $('#search-live-results').show();
    } else {
      $('#search-live-results').hide();
    }
    $('#search-live-results').html(`<div class="alert alert-light">Searching for ${query}...</div>`);
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      searchLive(query);
    }, 1000);
  });
});
