$(document).on('ready turbolinks:load', function() {
  // On selecting a date via pickadate auto submit form
  $('form.events-filters').find('input, select').each(function() {
    $(this).change(function() {
      setTimeout(function() {
        const form = $('form.events-filters');
        Turbolinks.visit('?' + form.serialize());
      }, 1000);
    });
  });
});
