$(document).on('ready turbolinks:load', function() {
  $('#id_date, .dateinput').pickadate({
    format: 'yyyy-mm-dd',
    formatSubmit: 'yyyy-mm-dd',
    hiddenName: true,
    selectMonths: true,
    selectYears: 128,
  });
  $('.timeinput').pickatime({
    format: 'h:i a',
    formatSubmit: 'H:i',
    hiddenName: true,
  });
});
